import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Component } from '@ux/uxcore2';

import { provideContext } from '../RenderContext';
import Card from '../Card';
import DomainSearch from '../DomainSearch';
import AtMostOnePromotionBanner from '../PromotionBanners/AtMostOnePromotionBanner';

export class DomainRegistration extends Component {
  componentDidMount() {
    this.breakpoint('mobile', 'phablet', 'tablet', 'desktop', 'large');
  }

  componentWillUnmount() {
    this.breakup();
  }

  render() {
    const { isMobile } = this;
    const { tag } = this.props;
    const { products } = tag;
    const product = products[0];

    return (
      <Fragment>
        <AtMostOnePromotionBanner
          preference={ ['domains'] }
          skipNavigationCheck={ true }
        />

        <Card className="product-card">
          <div className="row">
            { isMobile && <DomainSearch /> }

            <div className="product-details">
              { !isMobile && <DomainSearch className="inline" /> }

              <h4 className="product-title">{ product.title }</h4>

              <div
                className="product-description"
                dangerouslySetInnerHTML={{ __html: product.content }}
              />
            </div>
          </div>
        </Card>
      </Fragment>
    );
  }
}

DomainRegistration.propTypes = {
  tag: PropTypes.object.isRequired
};

export default provideContext(DomainRegistration);
