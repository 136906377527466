import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { provideContext } from '../RenderContext';
import ProductTitle from '../ProductTitle';
import DomainRegistration from './';

export class DomainRegistrationWrapper extends Component {
  render() {
    return (
      <div className="products domain-registration">
        <ProductTitle { ...this.props.tag } />
        <div className="container row">
          <DomainRegistration /><br />
        </div>
      </div>
    );
  }
}

DomainRegistrationWrapper.propTypes = {
  tag: PropTypes.object.isRequired
};

export default provideContext(DomainRegistrationWrapper);
