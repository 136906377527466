(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("react-intl")["default"], require("ux"), require("react-dom"), require("react-trfq")["default"], require("@ux/component")["default"]);
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "react-intl", "ux", "react-dom", "react-trfq", "@ux/component"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("prop-types"), require("react-intl")["default"], require("ux"), require("react-dom"), require("react-trfq")["default"], require("@ux/component")["default"]) : factory(root["React"], root["PropTypes"], root["ux"]["intl"], root["ux"], root["ReactDOM"], root["ux"]["trfq"], root["ux"]["Component"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__28__, __WEBPACK_EXTERNAL_MODULE__60__, __WEBPACK_EXTERNAL_MODULE__333__) {
return 