import { validation } from 'rcc-helpers';

const REASON_LIMIT = 240;

export default values => {
  const errors = {};

  if (!values.emailAddress || values.emailAddress.trim() === '') {
    errors.emailAddress = 'required';
  }
  else if (!validation.emailAddress(values.emailAddress)) {
    errors.emailAddress = 'invalid';
  }

  if (!values.type) {
    errors.type = 'required';
  }

  if (!values.reason || values.reason.trim() === '') {
    errors.reason = 'required';
  }
  else if (values.reason.trim().length > REASON_LIMIT) {
    errors.reason = 'tooLong';
  }

  return errors;
};

export { REASON_LIMIT };
