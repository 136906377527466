import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { SearchPageResellerComponent as SearchPage } from '@find/domain-iq-ui';
import { utils } from '@ux/uxcore2';
import { provideContext } from '../RenderContext';
import AtMostOnePromotionBanner from '../PromotionBanners/AtMostOnePromotionBanner';

const getDppLanguageStrings = (messages, market) => {
  let priceControlJson;

  try {
    priceControlJson = require(`@find/price-control-i18n/resources/${market}.json`);
  }
  catch {
    priceControlJson = require(`@find/price-control-i18n/resources/en-US.json`);
  }

  const prefix = 'dpp.';
  const dppLanguageStrings = Object.keys(messages)
    .filter(key => key.includes(prefix))
    .reduce((dppMessages, key) => {
      const genericKey = key.replace(prefix, '');

      dppMessages[genericKey] = messages[key];

      return dppMessages;
    }, {});

  return {
    ...dppLanguageStrings,
    priceControl: priceControlJson
  };
};

const getInitProps = settings => {
  const defaultProps = {
    appName: 'findSearch',
    apiKey: 'dpp_search',
    numberOfSpins: 20,
    urlOverride: `${settings.envPrefix}secureserver.net`,
    privateLabelId: settings.privateLabelId,
    companyName: settings.displayName,
    trackingCode: 'itc_test',
    excludePromo: false,
    eid: 'none',
    showBundles: false,
    showCrossSell: true,
    showSpins: true,
    showPricing: true
  };

  const market = settings.market;
  const currency = settings.currency;
  const language = getDppLanguageStrings(settings.messages, market);

  const url = new utils.URL(window.location, true);
  const domainToCheck = url.query.domainToCheck;

  // TODO: Remove this once the `@find` dependency is updated to pipe `language`
  // from `initProps` through to other components internally instead of relying
  // on `window.language`.
  window.language = language;
  // This is a hack to get around the lack of API prefetch functionality in the reseller SERP module.
  window.apiTelemetryCache = [];

  return {
    ...defaultProps,
    language,
    market,
    currency,
    domainToCheck
  };
};

class DomainRegistration extends Component {
  constructor() {
    super(...arguments);

    this.state = {};
  }

  componentDidMount() {
    this.setState({
      shouldRenderSerp: true
    });
  }

  render() {
    return (
      <Fragment>
        <AtMostOnePromotionBanner
          preference={ ['domains'] }
          skipNavigationCheck={ true }
        />

        {this.state.shouldRenderSerp && (
          <SearchPage
            skipPrefetchApis={ true }
            initProps={ getInitProps(this.props.settings) }
          />
        )}
      </Fragment>
    );
  }
}

DomainRegistration.propTypes = {
  settings: PropTypes.object.isRequired
};

export default provideContext(DomainRegistration);
