import React, { createContext } from 'react';

const RenderContext = createContext({});

export default RenderContext;

const provideContext = WrappedComponent => props => (
  <RenderContext.Consumer>
    {context => (
      <WrappedComponent { ...context } { ...props } />
    )}
  </RenderContext.Consumer>
);

const Provider = RenderContext.Provider;

export {
  RenderContext,
  provideContext,
  Provider
};
