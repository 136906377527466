import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Captcha from 'react-google-recaptcha';

export default class Recaptcha extends Component {
  execute() {
    this.element.reset();
    this.element.execute();
  }

  render() {
    const {
      locale,
      siteKey,
      onChange
    } = this.props;

    return (
      <div className="captcha-recaptcha">
        <Captcha
          hl={ locale }
          sitekey={ siteKey }
          onChange={ recaptcha => onChange({ recaptcha }) }
          size="invisible"
          ref={ element => {
            this.element = element;
          } }
        />
      </div>
    );
  }
}

Recaptcha.propTypes = {
  locale: PropTypes.string.isRequired,
  siteKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
