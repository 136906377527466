import Home from './components/Home';
import DomainTransfer from './components/DomainTransfer/wrapper';
import DomainRegistration from './components/DomainRegistration/wrapper';
import DomainRegistrationFind from './components/DomainRegistration/find';
import Products from './components/Products';
import LegalAgreements from './components/LegalAgreements';
import LegalAgreement from './components/LegalAgreements/agreement';
import ContactUs from './components/ContactUs';
import WhoIs from './components/WhoIs';
import Unsubscribe from './components/Unsubscribe';
import Redemption from './components/Redemption';
import NotFound from './components/NotFound';

export default [{
  key: 'home',
  path: '/',
  exact: true,
  component: Home
}, {
  key: 'products/domain-transfer',
  path: '/products/domain-transfer',
  component: DomainTransfer
}, {
  key: 'products/domain-registration',
  path: '/products/domain-registration',
  exact: true,
  component: DomainRegistration
}, {
  key: 'products/domain-registration/find',
  path: '/products/domain-registration/find',
  component: DomainRegistrationFind
}, {
  key: 'products',
  path: '/products/:tag',
  component: Products
}, {
  key: 'legalAgreements',
  path: '/legal-agreements',
  exact: true,
  component: LegalAgreements
}, {
  key: 'legalAgreement',
  path: '/legal-agreement',
  component: LegalAgreement
}, {
  key: 'contactUs',
  path: '/contact-us',
  component: ContactUs
}, {
  key: 'whois',
  path: '/whois',
  component: WhoIs
}, {
  key: 'unsubscribe',
  path: '/unsubscribe',
  component: Unsubscribe
}, {
  key: 'redemption',
  path: '/redemption',
  component: Redemption
}, {
  key: 'notFound',
  component: NotFound
},
// Component-less tokens so that `match-route` middleware doesn’t 404 on
// these paths.
{
  key: 'set-preference',
  path: '/set-preference'
}, {
  key: 'cookie-test',
  path: '/cookie-test'
}];
