import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '@ux/uxcore2';

import { provideContext } from '../RenderContext';

class Interstitial extends Component {
  constructor() {
    super(...arguments);

    this.state = {
      canInteract: false
    };
  }

  componentDidMount() {
    this.setState({
      canInteract: true
    });
  }

  render() {
    const {
      settings,
      handleContinueAsGuest
    } = this.props;

    const { canInteract } = this.state;

    const {
      envPrefix,
      privateLabelId,
      providesOwnSupport
    } = settings;

    const queryString = `?pl_id=${privateLabelId}&path=%2fcontact-us&app=www`;

    return (
      <div className="contactUs-interstitial">
        <div className="row">
          <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3">
            <p>
              <Button
                design="primary"
                href={ `https://sso.${envPrefix}secureserver.net/${queryString}` }
                data-eid="storefront.contact_us.interstitial.sign_in.click"
              >
                <FormattedMessage id="contactUs.interstitial.signIn" />
              </Button>
            </p>
            <p>
              <FormattedMessage
                id="contactUs.interstitial.createPrompt"
                values={{
                  a: msg => (
                    <a
                      href={ `https://sso.${envPrefix}secureserver.net/v1/account/create${queryString}` }
                      data-eid="storefront.contact_us.interstitial.create_account.click"
                    >
                      {msg}
                    </a>
                  )
                }}
              />
            </p>
            { !providesOwnSupport &&
              <p>
                <Button
                  design="default"
                  onClick={ handleContinueAsGuest }
                  data-eid="storefront.contact_us.interstitial.continue_as_guest.click"
                  disabled={ !canInteract }
                >
                  <FormattedMessage id="contactUs.interstitial.continueAsGuest" />
                </Button>
              </p>
            }
          </div>
        </div>
      </div>
    );
  }
}

Interstitial.propTypes = {
  settings: PropTypes.object.isRequired,
  handleContinueAsGuest: PropTypes.func.isRequired
};

export default provideContext(Interstitial);
