import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { promotions } from 'rcc-helpers';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Alert, Tooltip } from '@ux/uxcore2';

import PromotionBanner from './PromotionBanner';

class SecurityBundlePromotionBanner extends PromotionBanner {
  constructor() {
    super();

    this.dismissCookieName = 'sf.suppressBanner.securityBundlePromotion';
    this.renderAlert = this.renderAlert.bind(this);
    this.promoCode = promotions.data.find(p => p.key === '30-percent-off-security-bundle').iscCode.toUpperCase();
  }

  renderAlert() {
    return (
      <Alert
        className="securityBundlePromotionBanner"
        type="offer"
        onClose={ this.handleDismiss }
        message={ (
          <Fragment>
            <FormattedMessage
              id="home.securityBundlePromotionBanner.title"
              values={{
                promoCode: <strong>{this.promoCode}</strong>
              }}
            />
            <Tooltip
              message={
                this.props.intl.formatMessage({ id: 'home.securityBundlePromotionBanner.tooltip' })
              }
            />
          </Fragment>
        ) }
      />
    );
  }
}

SecurityBundlePromotionBanner.propTypes = {
  intl: PropTypes.object.isRequired
};

export default injectIntl(SecurityBundlePromotionBanner);
