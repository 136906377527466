import React from 'react';
import PropTypes from 'prop-types';
import camelCase from 'camelcase';

import * as svgs from './svgs';

const validIds = Object.keys(svgs);

const ProductIcon = ({ id }) => {
  const camelCaseId = camelCase(id);

  const props = {
    className: 'product-icon'
  };

  if (validIds.indexOf(camelCaseId) > -1) {
    props.dangerouslySetInnerHTML = {
      __html: svgs[camelCaseId]
    };
  }

  return (
    <div { ...props } />
  );
};

ProductIcon.propTypes = {
  id: PropTypes.string.isRequired
};

export default ProductIcon;
