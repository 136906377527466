import { createPortal } from 'react-dom';

import { Component, utils } from '@ux/uxcore2';

export default class PromotionBanner extends Component {
  constructor() {
    super();

    this.handleDismiss = this.handleDismiss.bind(this);
    this.state.shouldRender = false;
  }

  componentDidMount() {
    this.breakpoint('mobile', 'phablet', 'tablet', 'desktop', 'large');

    if (!!this.dismissCookieName && !utils.cookie.get(this.dismissCookieName)) {
      this.setState({ shouldRender: true });
    }
  }

  componentWillUnmount() {
    this.breakup();
  }

  handleDismiss() {
    utils.cookie.set(this.dismissCookieName, '1');

    this.setState({ shouldRender: false });
  }

  render() {
    return this.state.shouldRender && createPortal(
      this.renderAlert(),
      global.document.getElementById('banner')
    );
  }
}
