import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';

import { Button, Spinner } from '@ux/uxcore2';

import { provideContext } from '../RenderContext';
import validate from './validate';
import Recaptcha from '../Recaptcha';
import util from '../../util';

class InnerForm extends Component {
  constructor() {
    super(...arguments);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    const { initialValues } = this.props;

    if (initialValues.domain) {
      this.handleFormSubmit();
    }
  }

  handleFormSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    this.captcha.execute();
  }

  render() {
    const {
      settings,
      canInteract,
      values,
      dirty,
      errors,
      handleBlur,
      handleChange,
      isSubmitting,
      setFieldValue,
      setFieldTouched,
      submitForm
    } = this.props;

    const disabled = !canInteract || isSubmitting;
    const hasErrors = !!Object.keys(errors).length;

    return (
      <form onSubmit={ this.handleFormSubmit }>
        <Recaptcha
          locale={ settings.locale }
          siteKey={ settings.recaptcha.siteKey }
          ref={ captcha => {
            this.captcha = captcha;
          } }
          onChange={ ({ recaptcha }) => {
            setFieldValue('recaptcha', recaptcha);

            submitForm();
          } }
        />

        <div className="search-form">
          <input
            className="form-control search-form-input"
            data-eid="storefront.whois.search.input"
            disabled={ disabled }
            name="domain"
            value={ values.domain || '' }
            onBlur={ handleBlur }
            onChange={ handleChange }
            onKeyDown={ () => setFieldTouched('domain') }
          />

          <Button
            design="primary"
            type="submit"
            data-eid="storefront.whois.search.button.click"
            disabled={ disabled || hasErrors || !dirty }
            className="search-form-button"
          >
            { isSubmitting && (
              <Spinner size="sm" inline shade="dark" />
            ) }
            <FormattedMessage id="whois.form.button" />
          </Button>
        </div>
      </form>
    );
  }
}

InnerForm.propTypes = {
  ...util.formikPropTypes,
  settings: PropTypes.object.isRequired,
  canInteract: PropTypes.bool.isRequired
};

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      domain: props.settings.location.query.domain
    };
  }

  render() {
    const {
      settings,
      handleSubmit,
      canInteract
    } = this.props;

    const { domain } = this.state;

    const formProps = {
      validate,
      onSubmit: handleSubmit,
      initialValues: { domain },
      render: formikProps => (
        <InnerForm settings={ settings } canInteract={ canInteract } { ...formikProps } />
      )
    };

    return <Formik { ...formProps } />;
  }
}

Form.propTypes = {
  settings: PropTypes.object.isRequired,
  canInteract: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default provideContext(Form);
