import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { promotions } from 'rcc-helpers';
import { Alert, Tooltip } from '@ux/uxcore2';

import PromotionBanner from './PromotionBanner';

const tlds = promotions.data
  .find(promotion => promotion.key === 'year-long-domain-promotion')
  .pricing.map(productPricing => '.' + productPricing.product.toUpperCase())
  .sort();

export default class DomainPromotionBanner extends PromotionBanner {
  constructor() {
    super();

    this.dismissCookieName = 'sf.suppressBanner.domainPromotion';
    this.renderAlert = this.renderAlert.bind(this);
  }

  renderAlert() {
    return (
      <Alert
        className="domainPromotionBanner"
        type="offer"
        onClose={ this.handleDismiss }
        message={ (
          <Fragment>
            <FormattedMessage id="home.domainPromotionBanner" />
            <Tooltip message={ tlds.join(', ') } />
          </Fragment>
        ) }
      />
    );
  }
}
