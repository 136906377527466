import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, ButtonSet, Button, utils } from '@ux/uxcore2';

import { RenderContext } from '../RenderContext';

const cookieName = 'suppressForwardedFromModal';

const ForwardedFromModal = () => {
  const { settings } = useContext(RenderContext);
  const { displayName, forwardedFromDisplayName } = settings;
  const [shouldRenderModal, setShouldRenderModal] = useState(false);

  useEffect(() => {
    const isSuppressedByCookie = !!utils.cookie.get(cookieName);

    setShouldRenderModal(!!forwardedFromDisplayName && !isSuppressedByCookie);
  }, []);

  const handleCloseModal = () => {
    utils.cookie.set(cookieName, 'true', { path: '/' });

    setShouldRenderModal(false);
  };

  return shouldRenderModal && (
    <Modal
      className="text-black forwarded-from"
      onClose={ handleCloseModal }
      disableAutoClose={ true }
    >
      <p>
        <strong>
          <FormattedMessage
            id="home.forwardedFrom.body"
            values={{
              displayName,
              forwardedFromDisplayName
            }}
          />
        </strong>
      </p>
      <ButtonSet>
        <Button onClick={ handleCloseModal }>
          <FormattedMessage id="home.forwardedFrom.close" />
        </Button>
      </ButtonSet>
    </Modal>
  );
};

export default ForwardedFromModal;
