import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({ children }) => (
  <div className="ux-pagetitle">
    <div className="container">
      {children}
    </div>
  </div>
);

PageTitle.propTypes = {
  children: PropTypes.node
};

export default PageTitle;
