import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { provideContext } from '../RenderContext';
import ProductCard from '../ProductCard';

export class Super extends Component {
  render() {
    const { tag } = this.props;
    const { products } = tag;

    return (
      <div className="home super">
        <div className="home-hero bg-primary-o-highlight text-center">
          <div className="container">
            <h1 className="headline-brand">
              <FormattedMessage id="superHome.hero.title" />
            </h1>
            <p><FormattedMessage id="superHome.hero.subtitle" /></p>
          </div>
        </div>

        <div className="home-products">
          <div className="container">
            <div className="row">
              { products.map(product => (
                <div key={ product.id } className="col-lg-6">
                  <ProductCard product={ product } />
                </div>
              )) }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Super.propTypes = {
  tag: PropTypes.object.isRequired
};

export default provideContext(Super);
