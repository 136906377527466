import React, { Component, Fragment } from 'react';

import {
  injectIntl,
  FormattedMessage
} from 'react-intl';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { provideContext } from '../RenderContext';
import DomainSearch from '../DomainSearch';
import ProductIcon from '../ProductIcon';
import AtMostOnePromotionBanner from '../PromotionBanners/AtMostOnePromotionBanner';
import util from '../../util';
import ForwardedFromModal from './forwardedFromModal';

export class Turnkey extends Component {
  render() {
    const {
      settings,
      navigation,
      support,
      tags,
      domainRegistrationProduct
    } = this.props;

    const { displayName, heroImage, privateLabelId } = settings;

    const productCalloutKeys = util.getProductCalloutKeys(navigation);

    const showCpanel = productCalloutKeys.includes('cpanel');
    const showEmail = productCalloutKeys.includes('email');
    const showDomainRegistration = productCalloutKeys.includes('domainRegistration');

    const showDomainRegistrationAndLoaded = showDomainRegistration
      && !!domainRegistrationProduct;

    const calloutTagIds = [
      showCpanel && 'cpanel',
      showEmail && 'email',
      showDomainRegistration && 'domain-registration'
    ].filter(Boolean);

    let headLine;

    if (showDomainRegistrationAndLoaded) {
      const { tld, listPrice, salePrice } = domainRegistrationProduct;

      if (salePrice) {
        headLine = (
          <div className="home-hero-tld">
            <ProductIcon id="hotDeals" />
            <div className="home-hero-tld-pricing">
              <span>
                <FormattedMessage
                  id="home.hero.saleTldTitle"
                  values={{
                    tld: tld.toUpperCase(),
                    listPrice,
                    s: (msg) => (
                      <s>{msg}</s>
                    )
                  }}
                />
              </span>
              <span>
                <FormattedMessage
                  id="home.hero.salePriceTitle"
                  values={{ salePrice }}
                />
              </span>
            </div>
          </div>
        );
      }
      else {
        headLine = <FormattedMessage id="home.hero.tldTitle" values={{ tld: tld.toUpperCase(), listPrice }} />;
      }
    }
    else {
      headLine = <FormattedMessage id="home.hero.title" values={{ displayName }} />;
    }

    return (
      <Fragment>
        <AtMostOnePromotionBanner preference={ ['securityBundle', 'hosting', 'domains'] } />

        <ForwardedFromModal />

        <div className="home">
          <div className="home-hero bg-primary-o-highlight text-center"
            style={{ backgroundImage: heroImage && `url(${heroImage})` }}>
            <div className={ classNames('container', { 'home-hero-text': !!heroImage }) }>
              <h1 className="headline-brand">
                {headLine}
              </h1>
              { showDomainRegistration && (
                <div>
                  { showEmail && showCpanel && (
                    <p className="home-hero-subtitle"><FormattedMessage id="home.hero.subtitle" /></p>
                  )}
                  <DomainSearch eidContext="home" buttonDesign="brand" />
                </div>
              )}
            </div>
          </div>

          { !!tags && calloutTagIds.length > 0 && (
            <div className="home-products">
              <div className="container">
                <h2 className="sr-only"><FormattedMessage id="home.products.title" /></h2>
                <div className="row home-products-row">
                  { calloutTagIds.map(tagId => {
                    const tag = tags.find(t => t.id === tagId);

                    return tag && (
                      <div key={ tag.id } className="col-md-4 text-center home-products-product">
                        <ProductIcon id={ tag.imageId } />
                        <h3>
                          <a
                            href={ `/products/${tag.id}?plid=${privateLabelId}` }
                            data-eid={ `storefront.home.product_highlights.${tag.id}.link.click` }
                          >{ tag.title }</a>
                        </h3>
                        <p>{ tag.subtitle }</p>
                      </div>
                    );
                  }) }
                </div>
              </div>
            </div>
          ) }

          { support && !!support.technical && (
            <div className="home-contact bg-faded text-center">
              <div className="container">
                <div className="home-contact-info">
                  <h2><FormattedMessage id="home.contact.title" /></h2>
                  <p><FormattedMessage id="home.contact.body" /></p>
                  <p className="lead">
                    <FormattedMessage
                      id="home.contact.cta"
                      values={{
                        supportPhoneNumber: support.technical,
                        a: (msg) => (
                          <a href={ `tel:${util.stripNonNumerals(support.technical)}` }>{msg}</a>
                        )
                      }}
                    />
                  </p>
                  { util.shouldRenderEnglishOnlySupportDisclaimer(settings) && (
                    <p className="contactUs-englishOnly"><FormattedMessage id="contactUs.englishOnly" /></p>
                  ) }
                </div>
              </div>
            </div>
          ) }

          { showDomainRegistrationAndLoaded && (
            <div className="home-taxAndFeeDisclaimer">
              <div className="container">
                <p>
                  <FormattedMessage id="home.disclaimer"
                    values={{
                      disclaimer: this.props.intl.formatMessage({
                        id: `products.taxAndFeeDisclaimers.${domainRegistrationProduct.displayTaxesAndFees}`
                      })
                    }}
                  />
                </p>
              </div>
            </div>
          ) }
        </div>
      </Fragment>
    );
  }
}

Turnkey.propTypes = {
  settings: PropTypes.object.isRequired,
  navigation: PropTypes.object,
  support: PropTypes.object,
  tags: PropTypes.array,
  domainRegistrationProduct: PropTypes.object,
  intl: PropTypes.object.isRequired
};

export default provideContext(injectIntl(Turnkey));
