import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import {
  utils,
  Alert,
  Button,
  CheckboxGroup,
  Growl,
  Spinner
} from '@ux/uxcore2';

import { provideContext } from '../RenderContext';
import Card from '../Card';
import PageTitle from '../PageTitle';

const { addGrowlMessage } = Growl;

class Unsubscribe extends Component {
  constructor(props) {
    super(props);

    this.handleCheckboxToggle = this.handleCheckboxToggle.bind(this);
    this.handleUnsubscribeClick = this.handleUnsubscribeClick.bind(this);

    this.state = {
      encryptedEmail: props.settings.location.query.ee,
      isProcessing: true,
      unsubscribed: false,
      subscriptions: [],
      subscriptionsToCancel: []
    };
  }

  componentDidMount() {
    this.getSubscriptions();
  }

  getSubscriptions() {
    const { intl } = this.props;
    const { envPrefix, privateLabelId } = this.props.settings;
    const url = `https://gui.${envPrefix}secureserver.net/v1/subscription/list`;
    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      query: {
        EncryptedEmailAddress: this.state.encryptedEmail,
        plid: privateLabelId
      }
    };

    utils.request.get(url, options, (error, response) => {
      if (error) {
        this.setState({ isProcessing: false });

        return void addGrowlMessage({
          title: intl.formatMessage({ id: 'unsubscribe.growls.error.title' }),
          content: intl.formatMessage({ id: 'unsubscribe.growls.error.content' }),
          icon: 'error',
          fadeTime: 5000
        });
      }

      this.setState({
        isProcessing: false,
        subscriptions: response.subscriptions
      });
    });
  }

  handleCheckboxToggle(e) {
    const { value, checked } = e.target;

    if (checked) {
      this.setState(prevState => ({
        subscriptionsToCancel: [...prevState.subscriptionsToCancel, value]
      }));
    }
    else {
      this.setState(prevState => ({
        subscriptionsToCancel: prevState.subscriptionsToCancel.filter(s  => s !== value)
      }));
    }
  }

  // Delete subscriptions
  handleUnsubscribeClick() {
    this.setState({ isProcessing: true });

    const { intl } = this.props;
    const { envPrefix } = this.props.settings;

    const body = {
      EncryptedEmailAddress: this.state.encryptedEmail,
      ListNames: this.state.subscriptionsToCancel
    };

    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };

    const url = `https://gui.${envPrefix}secureserver.net/v1/subscription/unsubscribe`;

    utils.request.del(url, options, error => {
      if (error) {
        this.setState({ isProcessing: false });

        return void addGrowlMessage({
          title: intl.formatMessage({ id: 'unsubscribe.growls.error.title' }),
          content: intl.formatMessage({ id: 'unsubscribe.growls.error.content' }),
          icon: 'error',
          fadeTime: 5000
        });
      }

      this.setState({
        isProcessing: false,
        unsubscribed: true
      });
    });
  }

  render() {
    const { emailAddress } = this.props;
    const { privateLabelId, envPrefix, displayName } = this.props.settings;
    const { isProcessing, unsubscribed, subscriptions, subscriptionsToCancel } = this.state;

    let content;

    if (isProcessing) {
      content = (
        <div className="text-center">
          <Spinner inline size="lg" />
        </div>
      );
    }
    else if (unsubscribed || subscriptions.length === 0) {
      content = (
        <Card className="unsubscribe-card">
          <h2><FormattedMessage id="unsubscribe.card.unsubscribed.title"/></h2>
          <Alert
            type="success"
            message={ <FormattedMessage id="unsubscribe.card.unsubscribed.alert" /> }
            dismissible={ false }
          />
          <p>
            <FormattedMessage
              id="unsubscribe.card.unsubscribed.description"
              values={{
                emailAddress,
                strong: (msg) => (
                  <strong>{msg}</strong>
                )
              }}
            />
          </p>
          <Button
            design="primary"
            href={ `https://account.${envPrefix}secureserver.net/preferences?plid=${privateLabelId}` }
            data-eid="storefront.unsubscribe.email_preferences_button.click"
          >
            <FormattedMessage id="unsubscribe.card.unsubscribed.button" />
          </Button>
        </Card>
      );
    }
    else {
      content = (
        <Card className="unsubscribe-card">
          <h2><FormattedMessage id="unsubscribe.card.default.title"  values={{ displayName }}/></h2>
          <p>
            <FormattedMessage
              id="unsubscribe.card.default.description"
              values={{
                emailAddress,
                strong: (msg) => (
                  <strong>{msg}</strong>
                )
              }}
            />
          </p>

          <CheckboxGroup inputs={ subscriptions.map(s => ({
            key: s.listName,
            label: s.displayName,
            value: s.listName,
            onChange: this.handleCheckboxToggle
          })) } />

          <Button
            design="primary"
            type="submit"
            onClick={ this.handleUnsubscribeClick }
            data-eid="storefront.unsubscribe.unsubscribe_button.click"
            disabled={ subscriptionsToCancel.length === 0 }
          >
            <FormattedMessage id="unsubscribe.card.default.button" />
          </Button>
        </Card>
      );
    }

    return (
      <div className="unsubscribe">
        <PageTitle>
          <h1 className="headline-brand">
            <FormattedMessage id="unsubscribe.title" />
          </h1>
        </PageTitle>

        <div className="container row">
          <div className="col-xs-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
            { content }
          </div>
        </div>
      </div>
    );
  }
}

Unsubscribe.propTypes = {
  intl: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  emailAddress: PropTypes.string
};

export default provideContext(injectIntl(Unsubscribe));
