import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { utils, Alert, Button, Growl } from '@ux/uxcore2';

import { provideContext } from '../RenderContext';
import PageTitle from '../PageTitle';
import Card from '../Card';
import Form from './form';
import ContactForm from './ContactForm';

const { addGrowlMessage } = Growl;

class WhoIs extends Component {
  constructor() {
    super(...arguments);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleContactOpenClick = this.handleContactOpenClick.bind(this);
    this.handleContactCloseClick = this.handleContactCloseClick.bind(this);

    this.state = {
      canInteract: false,
      showContactModalOnSearch: this.props.settings.location.query.showContact === 'true'
    };
  }

  componentDidMount() {
    this.setState({
      canInteract: true
    });
  }

  handleContactOpenClick(e) {
    if (e) {
      e.preventDefault();
    }

    this.setState({ isContactModalOpen: true });
  }

  handleContactCloseClick() {
    this.setState({ isContactModalOpen: false });
  }

  handleSubmit(values, { setSubmitting }) {
    const {
      domain,
      recaptcha
    } = values;

    const {
      intl,
      settings
    } = this.props;

    const {
      envPrefix,
      privateLabelId
    } = settings;

    const query = { privateLabelId, recaptcha };

    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      query
    };

    const url = `https://www.${envPrefix}secureserver.net/api/v1/whois/${domain.toLowerCase()}`;

    utils.request.get(url, options, (error, whoisResponse) => {
      setSubmitting(false);

      if (error) {
        if (error.status === 404) {
          return void this.setState({
            domain,
            isAvailable: true,
            record: null
          });
        }
        else if (error.status === 403) {
          return void this.setState({
            domain,
            isAvailable: false,
            record: null
          });
        }

        return void addGrowlMessage({
          title: intl.formatMessage({ id: 'whois.growls.error.title' }),
          content: intl.formatMessage({ id: 'whois.growls.error.content' }),
          icon: 'error',
          fadeTime: 5000
        });
      }

      this.setState({
        domain,
        isAvailable: false,
        isManaged: whoisResponse.isManaged,
        record: whoisResponse.record.trim(),
        isContactModalOpen: whoisResponse.isManaged && this.state.showContactModalOnSearch,
        showContactModalOnSearch: false
      });
    });
  }

  render() {
    const { intl, settings } = this.props;

    const {
      canInteract,
      domain,
      isAvailable,
      isManaged,
      record,
      isContactModalOpen
    } = this.state;

    let body;

    const {
      envPrefix,
      privateLabelId
    } = settings;

    if (domain) {
      if (record) {
        body = (
          <div className="whois-record row">
            <div className="col-md-4 whois-record-sidebar">
              <h2><FormattedMessage id="whois.sidebar.header" /></h2>
              <p><FormattedMessage id="whois.sidebar.cta" /></p>
              <Button design="primary" href={ `https://mya.${envPrefix}secureserver.net/?pl_id=${privateLabelId}` }>
                <FormattedMessage id="whois.sidebar.action" />
              </Button>
            </div>

            <div className="col-md-8">
              <div>
                <h2><FormattedMessage id="whois.record.header" /></h2>
                <pre>{ record }</pre>
              </div>

              { isManaged && (
                <p>
                  <a /* eslint-disable-line jsx-a11y/anchor-is-valid */
                    href="#"
                    data-eid="storefront.whois.contact.open.click"
                    onClick={ this.handleContactOpenClick }
                  >
                    <FormattedMessage id="whois.contactRegistrant" />
                  </a>
                </p>
              ) }
            </div>
          </div>
        );
      }
      else if (isAvailable) {
        // eslint-disable-next-line max-len
        const findHref = `https://www.${envPrefix}secureserver.net/products/domain-registration/find?plid=${privateLabelId}&domainToCheck=${domain}`;

        body = (
          <div className="whois-available row">
            <div className="col-md-8 offset-md-2">
              <h2>
                <FormattedMessage
                  id="whois.available.header"
                  values={{
                    domain,
                    span: (msg) => (
                      <span className="text-primary">{msg}</span>
                    )
                  }}
                />
              </h2>
              <p>
                <Button href={ findHref } design="primary">
                  <FormattedMessage id="whois.available.action" />
                </Button>
              </p>
            </div>
          </div>
        );
      }
      else {
        const lastIndexOfDot = domain.lastIndexOf('.');
        const extension = domain.substring(lastIndexOfDot);

        body = (
          <div className="whois-notAvailable row">
            <div className="col-md-8 offset-md-2">
              <Alert
                type="warning"
                header={ intl.formatMessage({ id: 'whois.notAvailable.header' }) }
                message={ intl.formatMessage({ id: 'whois.notAvailable.body' }, { extension }) }
                dismissible={ false }
              />
            </div>
          </div>
        );
      }
    }
    else {
      body = (
        <div className="whois-interstitial">
          <h2><FormattedMessage id="whois.interstitial.title" /></h2>
          <FormattedMessage
            id="whois.interstitial.gdprBody"
            values={{
              p: (msg) => (
                <p>{msg}</p>
              )
            }}
          />
        </div>
      );
    }

    return (
      <div className="whois">
        <PageTitle>
          <h1 className="headline-brand"><FormattedMessage id="whois.title" /></h1>
        </PageTitle>
        <div className="container row">
          <Card>
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <Form
                  onClose={ this.handleContactCloseClick }
                  canInteract={ canInteract }
                  handleSubmit={ this.handleSubmit }
                />
              </div>
            </div>

            { body }

            <hr />
            <FormattedMessage
              id="whois.disclaimer"
              values={{
                p: (msg) => (
                  <p>{msg}</p>
                ),
                small: (msg) => (
                  <small>{msg}</small>
                )
              }}
            />
          </Card>

          { isContactModalOpen && (
            <ContactForm initialValues={{ domain }} handleClose={ this.handleContactCloseClick } />
          ) }
        </div>
      </div>
    );
  }
}

WhoIs.propTypes = {
  intl: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired
};

export default provideContext(injectIntl(WhoIs));
