import { validation } from 'rcc-helpers';

export default values => {
  const errors = {};

  if (!values.domain) {
    errors.domain = 'required';
  }
  else if (!validation.hostname(values.domain)) {
    errors.domain = 'invalid';
  }

  return errors;
};
