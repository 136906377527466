import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Spinner, Component } from '@ux/uxcore2';

import { provideContext } from '../RenderContext';
import ProductTitle from '../ProductTitle';
import ProductCard from '../ProductCard';
import AtMostOnePromotionBanner from '../PromotionBanners/AtMostOnePromotionBanner';
import util from '../../util';

export class ProductsBase extends Component {
  constructor() {
    super(...arguments);

    this.getProductClassNames = this.getProductClassNames.bind(this);
    this.generateAdditionalTitleContent = this.generateAdditionalTitleContent.bind(this);
    this.generatePodsHeaderContent = this.generatePodsHeaderContent.bind(this);
    this.generatePodsFooterContent = this.generatePodsFooterContent.bind(this);

    const { tag } = this.props;

    this.state = {
      canInteract: false,
      tag,
      hasProducts: tag && tag.products && tag.products.length
    };
  }

  componentDidMount() {
    if (!this.state.hasProducts) {
      return util.redirect(`/?plid=${this.props.settings.privateLabelId}`);
    }

    this.breakpoint('mobile', 'phablet', 'tablet', 'desktop', 'large');

    this.setState({
      canInteract: true
    });
  }

  componentWillUnmount() {
    this.breakup();
  }

  generateAdditionalTitleContent() {
    return null;
  }

  generatePodsHeaderContent() {
    return null;
  }

  generatePodsFooterContent() {
    return null;
  }

  getProductClassNames(shouldRenderSingleColumn, n) {
    return classNames({
      'col-md-6': !shouldRenderSingleColumn,
      'clear-left': !shouldRenderSingleColumn && n % 2 === 0
    });
  }

  render() {
    if (!this.state.hasProducts) {
      return (
        <div />
      );
    }

    const { canInteract, tag, breakpoint } = this.state;
    const products = this.generateProducts(tag.products);
    const shouldRenderSingleColumn = ['domain-registration', 'domain-transfer'].includes(tag.id);
    const isMobile = this.isBrowser && ['mobile', 'phablet'].includes(breakpoint);

    return (
      <div className={ classNames('products', tag.id) }>
        <AtMostOnePromotionBanner
          preference={ ['securityBundle', 'hosting'] }
          productTag={ tag.id }
          skipNavigationCheck={ true }
        />

        <ProductTitle { ...tag }>
          { this.generateAdditionalTitleContent({ canInteract }) }
        </ProductTitle>

        <div className="container row">
          { !canInteract
            ? (
              <div className="text-center products--spinner">
                <Spinner inline size="lg" />
              </div>
            )
            : (
              <Fragment>
                {this.generatePodsHeaderContent()}
                {products.map((item, n) => (
                  <div key={ item.id } className={ this.getProductClassNames(shouldRenderSingleColumn, n) }>
                    <ProductCard
                      product={ item }
                      showMore={ !isMobile }
                      { ...this.productCardProps }
                    />
                  </div>
                ))}
                {this.generatePodsFooterContent()}
              </Fragment>
            )
          }
        </div>
      </div>
    );
  }
}

ProductsBase.propTypes = {
  settings: PropTypes.object.isRequired,
  tag: PropTypes.object
};

export default provideContext(ProductsBase);
