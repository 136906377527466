import { utils } from '@ux/uxcore2';
import PropTypes from 'prop-types';

const shouldShowChild = (category, key) => category &&
  !category.isHidden &&
  category.children &&
  !category.children[key].isHidden &&
  !category.children[key].url;

export default {
  snakeCase(value) {
    return value.replace(/-/g, '_');
  },

  stripNonNumerals(value) {
    return value && value.replace
      ? value.replace(/[^0-9]/g, '')
      : value;
  },

  buildDomainTransferUrl({ envPrefix, domain, privateLabelId, progId, itc }) {
    // eslint-disable-next-line max-len
    return `https://dcc.${envPrefix}secureserver.net/domains/transfer-in/${encodeURIComponent(domain)}?plid=${privateLabelId}&prog_id=${progId}&itc=${itc}`;
  },

  getShopper({ envPrefix, privateLabelId }, callback) {
    const infoIdp = utils.cookie.get('info_idp');

    if (!infoIdp) {
      return callback(new Error('No info_idp cookie present'));
    }

    const sid = JSON.parse(infoIdp).info_shopperId;
    const guiUrl = `https://gui.${envPrefix}secureserver.net/pcjson/salesheader?plId=${privateLabelId}&sid=${sid}`;

    utils.request.get(guiUrl, { jsonp: true }, (error, token) => {
      if (error) {
        return callback(error);
      }
      else if (!token || !token.shopperid) {
        return callback();
      }

      const {
        name: firstName,
        lastname: lastName,
        shopperid: shopperId
      } = token;

      return callback(null, {
        firstName,
        lastName,
        shopperId
      });
    });
  },

  shouldRenderEnglishOnlySupportDisclaimer(settings) {
    return !settings.providesOwnSupport && !settings.locale.startsWith('en-');
  },

  getProductCalloutKeys(navigation) {
    if (!navigation) {
      return [];
    }

    return [
      shouldShowChild(navigation.hosting, 'cpanel') && 'cpanel',
      shouldShowChild(navigation.workspace, 'email') && 'email',
      shouldShowChild(navigation.domains, 'domainRegistration') && 'domainRegistration'
    ].filter(Boolean);
  },

  formikPropTypes: {
    resetForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setSubmitting: PropTypes.func.isRequired,
    setTouched: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    initialValues: PropTypes.object.isRequired
  },

  redirect: target => {
    // `global.location.assign` is problematic in tests. Either isn’t defined
    // or is read-only and can’t be stubbed/spied.
    global.location.assign && global.location.assign(target);
  },

  shouldRenderConfig: ({ plan, packageId }) => !!(plan || packageId)
};
