import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Formik } from 'formik';
import { injectIntl, FormattedMessage } from 'react-intl';

import {
  FormElement as Input,
  ButtonSet,
  Button,
  Spinner,
  RadioGroup
} from '@ux/uxcore2';

import { provideContext } from '../../RenderContext';
import validate from './validate';
import Recaptcha from '../../Recaptcha';
import util from '../../../util';

const contactTypeValues = {
  registrant: 'Registrant',
  technical: 'Technical',
  admin: 'Administrative'
};

const contactReasons = [
  'PURCHASE_DOMAIN',
  'ABUSE_DOMAIN',
  'TRADEMARK_VIOLATIONS_DOMAIN',
  'OTHER_PURPOSE'
];

const contactReasonMapping = {
  PURCHASE_DOMAIN: 'I am interested in purchasing this domain. Please respond if you are interested in selling.',
  ABUSE_DOMAIN:
    'Your domain name or the content on your website might be being used in malware, or for spam or abuse. ' +
    'Please respond at the earliest.',
  TRADEMARK_VIOLATIONS_DOMAIN:
    'Your domain name or the content on your website may be infringing on a trademark ' +
    'and/or violating local laws or regulations. It is important that you respond at the earliest.',
  OTHER_PURPOSE: 'I would like to contact you regarding your domain and/or content on your website.'
};

export class InnerForm extends Component {
  constructor() {
    super(...arguments);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();

    this.captcha.execute();
  }

  render() {
    const {
      intl,
      settings,
      handleClose,
      values,
      touched,
      dirty,
      errors,
      handleBlur,
      handleChange,
      isSubmitting,
      setFieldValue,
      submitForm
    } = this.props;

    const disabled = isSubmitting;
    const hasErrors = !!Object.keys(errors).length;

    const contactTypes = Object.keys(contactTypeValues).map(type => ({
      label: intl.formatMessage({ id: `whois.contact.form.labels.types.${type}` }),
      id: `type-${type}`,
      name: 'type',
      value: contactTypeValues[type],
      inline: true,
      defaultChecked: contactTypeValues[type] === values.type,
      onChange: handleChange
    }));

    const contactOptions = contactReasons.map(reasonId => ({
      label: intl.formatMessage({ id: `whois.contact.form.labels.contactReason.${reasonId}` }),
      id: `reason-${reasonId}`,
      name: 'reason',
      value: contactReasonMapping[reasonId],
      inline: true,
      defaultChecked: contactReasonMapping[reasonId] === values.reason,
      onChange: handleChange
    }));

    return (
      <form onSubmit={ this.handleFormSubmit }>
        <FormattedMessage
          id="whois.contact.body"
          values={{
            p: (msg) => (
              <p>{msg}</p>
            ),
            strong: (msg) => (
              <strong>{msg}</strong>
            )
          }}
        />

        <div className="row">
          <div className="col-md-6">
            <Input
              id="whois-contact-domain"
              name="domain"
              value={ values.domain }
              label={ intl.formatMessage({ id: 'whois.contact.form.labels.domain' }) }
              disabled={ true }
              required={ true }
            />
          </div>

          <div className="col-md-6">
            <Input
              id="whois-contact-emailAddress"
              name="emailAddress"
              type="email"
              value={ values.emailAddress }
              label={ intl.formatMessage({ id: 'whois.contact.form.labels.emailAddress' }) }
              onChange={ handleChange }
              onBlur={ handleBlur }
              disabled={ disabled }
              required={ true }
              validates={ touched.emailAddress && !errors.emailAddress }
              failure={ touched.emailAddress &&
                errors.emailAddress &&
                intl.formatMessage({ id: `whois.contact.form.errors.${errors.emailAddress}` })
              }
            />
          </div>

          <div className="col-md-12">
            <label htmlFor="type">
              <FormattedMessage id="whois.contact.form.labels.type" />&nbsp;
              <span className="req" aria-label="required">*</span>
            </label>
            <RadioGroup inputs={ contactTypes } />
            { touched.type && errors.type && (
              <div className="form-error" role="alert">
                <FormattedMessage id="whois.contact.form.errors.required" />
              </div>
            ) }
          </div>

          <div className="col-md-12 whois-contact-reason">
            <label htmlFor="reason">
              <FormattedMessage id="whois.contact.form.labels.reason" />&nbsp;
              <span className="req" aria-label="required">*</span>
            </label>
            <RadioGroup inputs={ contactOptions } />
            { touched.reason && errors.reason && (
              <div className="form-error" role="alert">
                <FormattedMessage id="whois.contact.form.errors.required" />
              </div>
            ) }
          </div>

          <Recaptcha
            locale={ settings.locale }
            siteKey={ settings.recaptcha.siteKey }
            ref={ captcha => {
              this.captcha = captcha;
            } }
            onChange={ ({ recaptcha }) => {
              setFieldValue('recaptcha', recaptcha);

              submitForm();
            } }
          />

          <div className="col-md-12">
            <FormattedMessage
              id="whois.contact.disclaimer"
              values={{
                p: (msg) => (
                  <p>{msg}</p>
                ),
                utos: (msg) => (
                  <a
                    href={ `legal-agreement?id=utos&plid=${settings.privateLabelId}` }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {msg}
                  </a>
                ),
                legal: (msg) => (
                  <a
                    href={ `legal-agreement?id=privacy&plid=${settings.privateLabelId}` }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {msg}
                  </a>
                )
              }}
            />
          </div>
        </div>

        <ButtonSet>
          <Button
            design="primary"
            type="submit"
            data-eid="storefront.whois.contact.submit.click"
            disabled={ disabled || hasErrors || !dirty }
          >
            { isSubmitting && (
              <Spinner size="sm" inline shade="dark" />
            ) }
            <FormattedMessage id="whois.contact.form.actions.submit" />
          </Button>

          <Button
            design="default"
            data-eid="storefront.whois.contact.cancel.click"
            disabled={ disabled }
            onClick={ handleClose }
          >
            <FormattedMessage id="whois.contact.form.actions.cancel" />
          </Button>
        </ButtonSet>
      </form>
    );
  }
}

InnerForm.propTypes = {
  ...util.formikPropTypes,
  intl: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired
};

class Form extends Component {
  render() {
    const {
      intl,
      settings,
      initialValues,
      handleClose,
      handleSubmit: onSubmit
    } = this.props;

    const formProps = {
      validate,
      onSubmit,
      initialValues: { type: 'Registrant', ...initialValues },
      render: additionalProps => (
        <InnerForm intl={ intl } settings={ settings } handleClose={ handleClose } { ...additionalProps } />
      )
    };

    return <Formik { ...formProps } />;
  }
}

Form.propTypes = {
  intl: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default provideContext(injectIntl(Form));
