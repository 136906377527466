import { provideContext } from '../RenderContext';
import { ProductsBase } from './ProductsBase';

export class UnfilteredProducts extends ProductsBase {
  constructor() {
    super(...arguments);

    this.generateProducts = this.generateProducts.bind(this);
  }

  generateProducts(allProducts) {
    return allProducts;
  }
}

export default provideContext(UnfilteredProducts);
