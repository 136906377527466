import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';

import { Growl } from '@ux/uxcore2';

import routes from './routes';
import { Provider } from '../src/components/RenderContext';

const Storefront = ({ renderContext }) => (
  <IntlProvider locale={ renderContext.settings.locale } messages={ renderContext.settings.messages }>
    <Fragment>
      <Provider value={ renderContext }>
        <Switch>
          {routes.filter(r => !!r.component).map(props => {
            const {
              key,
              path,
              exact,
              component
            } = props;

            return (
              <Route key={ key } { ...{ path, exact, component } } />
            );
          })}
        </Switch>
      </Provider>
      <Growl />
    </Fragment>
  </IntlProvider>
);

Storefront.propTypes = {
  key: PropTypes.string,
  path: PropTypes.string,
  exact: PropTypes.bool,
  component: PropTypes.object,
  renderContext: PropTypes.object.isRequired
};

export default Storefront;
