import { provideContext } from '../RenderContext';
import { ProductsBase } from './ProductsBase';

export class FilteredProducts extends ProductsBase {
  constructor() {
    super(...arguments);

    this.generateProducts = this.generateProducts.bind(this);

    this.state = {
      ...this.state,
      filterIndex: 0
    };
  }

  generateProducts(allProducts) {
    const { filterIndex } = this.state;
    const { filter } = this.filters[filterIndex];

    return allProducts.filter(filter);
  }
}

export default provideContext(FilteredProducts);
