import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ProductTitle from '../ProductTitle';
import DomainTransfer from './';

export class DomainTransferWrapper extends Component {
  render() {
    return (
      <div className="products domain-transfer">
        <ProductTitle
          imageId="domainTransfer"
          title={ this.props.intl.formatMessage({ id: 'domainTransfer.title' }) }
        />
        <div className="container row">
          <DomainTransfer />
        </div>
      </div>
    );
  }
}

DomainTransferWrapper.propTypes = {
  intl: PropTypes.object.isRequired
};

export default injectIntl(DomainTransferWrapper);
