import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  injectIntl,
  FormattedMessage
} from 'react-intl';

import { utils, Alert, Growl, Spinner } from '@ux/uxcore2';

import { provideContext } from '../RenderContext';
import PageTitle from '../PageTitle';
import Card from '../Card';
import Sidebar from './sidebar';
import Interstitial from './interstitial';
import Form from './form';
import util from '../../util';

const { addGrowlMessage } = Growl;

class ContactUs extends Component {
  constructor() {
    super(...arguments);

    this.state = {
      continueAsGuest: false,
      isShopperLoaded: false
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleContinueAsGuest = this.handleContinueAsGuest.bind(this);
  }

  componentDidMount() {
    const { env, envPrefix, privateLabelId } = this.props.settings;

    // Ignore errors from GUI and treat as signed-out.
    util.getShopper({ env, envPrefix, privateLabelId }, (__, shopper) => {
      this.setState({
        isShopperLoaded: true,
        shopper: shopper || {}
      });
    });
  }

  handleFormSubmit(values, { setSubmitting }) {
    const {
      intl,
      settings
    } = this.props;

    const {
      envPrefix,
      privateLabelId
    } = settings;

    const url = `https://www.${envPrefix}secureserver.net/api/v1/contact/${privateLabelId}`;

    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    utils.request.post(url, values, options, (error, response) => {
      if (error) {
        addGrowlMessage({
          title: intl.formatMessage({ id: 'contactUs.growls.error.title' }),
          content: intl.formatMessage({ id: 'contactUs.growls.error.content' }),
          icon: 'error',
          fadeTime: 5000
        });

        return void setSubmitting(false);
      }

      this.setState({
        incidentId: response.incidentId,
        messageSent: true
      });
    });
  }

  handleContinueAsGuest() {
    this.setState({
      continueAsGuest: true
    });
  }

  render() {
    const { initialValues } = this.props;
    const { continueAsGuest, incidentId, messageSent, isShopperLoaded, shopper } = this.state;
    const isSignedIn = isShopperLoaded && !!shopper.shopperId;
    let body;

    if (!isShopperLoaded) {
      body = (
        <div className="text-center">
          <Spinner inline size="lg" />
        </div>
      );
    }
    else if (messageSent) {
      const message = incidentId
        ? (
          <FormattedMessage
            id="contactUs.confirmation.message"
            values={{
              incidentId,
              strong: (msg) => (
                <strong>{msg}</strong>
              )
            }}
          />
        )
        : <FormattedMessage id="contactUs.confirmation.customSupportMessage" />;
      body = (
        <Alert
          type="success"
          header={ <FormattedMessage id="contactUs.confirmation.header" /> }
          message={ message }
          dismissible={ false }
        />
      );
    }
    else if (isSignedIn || continueAsGuest) {
      body = (
        <Form shopper={ shopper } initialValues={ initialValues } handleSubmit={ this.handleFormSubmit } />
      );
    }
    else {
      body = (
        <Interstitial handleContinueAsGuest={ this.handleContinueAsGuest } />
      );
    }

    return (
      <div className="contactUs">
        <PageTitle>
          <h1 className="headline-brand">
            <FormattedMessage id="contactUs.title" />
          </h1>
        </PageTitle>

        <div className="container row">
          <Sidebar />
          <div className="col-lg-8">
            <Card className="contactUs-main">
              <h2>
                <FormattedMessage id="contactUs.form.title" />
              </h2>
              { body }
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

ContactUs.propTypes = {
  intl: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  initialValues: PropTypes.object
};

export default provideContext(injectIntl(ContactUs));
