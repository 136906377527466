import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Button } from '@ux/uxcore2';

import { provideContext } from '../RenderContext';
import util from '../../util';

class Form extends Component {
  constructor() {
    super(...arguments);

    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.state = {};
  }

  componentDidMount() {
    this.setState({
      canInteract: true
    });
  }

  handleSearchClick(e) {
    e.preventDefault();

    const {
      envPrefix,
      privateLabelId,
      progId,
      itc
    } = this.props.settings;

    global.location = util.buildDomainTransferUrl({
      envPrefix,
      domain: this.domain.value,
      privateLabelId,
      progId,
      itc
    });
  }

  render() {
    const { intl, className } = this.props;
    const { canInteract } = this.state;

    return (
      <form className={ classNames(className, 'search-form') } onSubmit={ this.handleSearchClick }>
        <input
          className="form-control search-form-input"
          name="domainToCheck"
          placeholder={ intl.formatMessage({ id: 'domainTransfer.cta' }) }
          data-eid="storefront.product.domain.transfer.input"
          ref={ i => {
            this.domain = i;
          } }
        />
        <Button
          design="primary"
          type="submit"
          className="search-form-button"
          data-eid="storefront.product.domain.transfer.button.click"
          disabled={ !canInteract }
        >
          <FormattedMessage id="domainTransfer.label" />
        </Button>
      </form>
    );
  }
}

Form.propTypes = {
  intl: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default provideContext(injectIntl(Form));
