import React from 'react';
import PropTypes from 'prop-types';

import { enums } from 'rcc-helpers';

import { provideContext } from '../RenderContext';
import Turnkey from './turnkey';
import Super from './super';

export const Home = ({ settings }) => {
  return settings.resellerTypeId === enums.resellerType.SUPER
    ? <Super />
    : <Turnkey />;
};

Home.propTypes = {
  settings: PropTypes.object.isRequired
};

export default provideContext(Home);
