import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { hydrate } from 'react-dom';

import Storefront from './storefront';

hydrate(
  <BrowserRouter>
    <Storefront renderContext={ global.__RENDER_CONTEXT__ } />
  </BrowserRouter>,
  document.getElementById('body')
);

delete global.__RENDER_CONTEXT__;
