import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Button } from '@ux/uxcore2';

import { provideContext } from '../RenderContext';

class DomainSearch extends Component {
  render() {
    const {
      intl,
      className,
      eidContext,
      buttonDesign,
      settings
    } = this.props;

    const {
      envPrefix,
      privateLabelId,
      useFind
    } = settings;

    const domainSearchAction = useFind
      ? `https://www.${envPrefix}secureserver.net/products/domain-registration/find`
      // eslint-disable-next-line max-len
      : `https://www.${envPrefix}secureserver.net/domains/search.aspx?checkAvail=1&pl_id=${privateLabelId}&iphoneview=1`;

    return (
      <form
        className={ classNames('search-form', className) }
        method={ useFind ? 'GET' : 'POST' }
        action={ domainSearchAction }
      >
        { useFind && <input name="plid" type="hidden" value={ privateLabelId } /> }
        <input
          className="form-control search-form-input"
          name="domainToCheck"
          placeholder={ intl.formatMessage({ id: 'domainSearch.cta' }) }
          data-eid={ `storefront.${eidContext}.domain.search.input` }
        />
        <Button
          design={ buttonDesign }
          type="submit"
          className="search-form-button"
          data-eid={ `storefront.${eidContext}.domain.search.button.click` }
        >
          <FormattedMessage id="domainSearch.label" />
        </Button>
      </form>
    );
  }
}

DomainSearch.propTypes = {
  intl: PropTypes.object.isRequired,
  className: PropTypes.string,
  eidContext: PropTypes.string,
  buttonDesign: PropTypes.string,
  settings: PropTypes.object.isRequired
};

DomainSearch.defaultProps = {
  eidContext: 'product',
  buttonDesign: 'primary'
};

export default provideContext(injectIntl(DomainSearch));
