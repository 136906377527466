import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { provideContext } from '../RenderContext';
import PageTitle from '../../components/PageTitle';
import Card from '../../components/Card';

const generateLink = (key, privateLabelId, legalAgreements) => {
  const props = {
    className: 'legalAgreements-link'
  };

  const token = legalAgreements.find(d => d.key === key);

  if (token.href) {
    props.href = token.href;
    props.target = '_blank';
  }
  else {
    props.href = `/legal-agreement?id=${token.id}&plid=${privateLabelId}`;
  }

  return (
    <a { ...props }><FormattedMessage id={ `legalAgreements.documentTitles.${key}` } /></a>
  );
};

const LegalAgreements = ({ settings, legalAgreements }) => (
  <div className="legalAgreements">
    <PageTitle>
      <h1 className="headline-brand"><FormattedMessage id={ `legalAgreements.title` } /></h1>
    </PageTitle>

    <div className="container row">
      <div className="col-md-12 legalAgreements-main">
        <Card>
          <h2>
            <FormattedMessage id={ `legalAgreements.fullTitle` } values={{ displayName: settings.displayName }} />
          </h2>
          <p>
            <FormattedMessage id={ `legalAgreements.description` } values={{ displayName: settings.displayName }} />
          </p>
          <p className="legalAgreements-utos">
            { generateLink('universal-terms-of-service-agreement', settings.privateLabelId, legalAgreements) }
          </p>
        </Card>
      </div>

      { ['agreement', 'policy'].map(type => (
        <div key={ type } className={ `col-md-6 legalAgreements-${type}` }>
          <Card>
            <h2><FormattedMessage id={ `legalAgreements.types.${type}.title` } /></h2>
            <ul>
              { legalAgreements.filter(d => d.type === type).map(document => (
                <li key={ document.key }>{ generateLink(document.key, settings.privateLabelId, legalAgreements) }</li>
              )) }
            </ul>
          </Card>
        </div>
      )) }
    </div>
  </div>
);

LegalAgreements.propTypes = {
  settings: PropTypes.object.isRequired,
  legalAgreements: PropTypes.array.isRequired
};

export default provideContext(LegalAgreements);
