import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Card = ({ className, children }) => (
  <div className={ classNames('card ux-card', className) }>
    <div className="card-block">
      {children}
    </div>
  </div>
);

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default Card;
