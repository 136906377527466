import React from 'react';
import PropTypes from 'prop-types';

export const ProductBanner = ({ imageUrl, title }) => (
  <div className="product-banner-image">
    <img src={ imageUrl } alt={ title }/>
  </div>
);

ProductBanner.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ProductBanner;
