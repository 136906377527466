import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import {
  FormElement as Input,
  Button,
  Dropdown,
  Spinner
} from '@ux/uxcore2';

const { DropdownItem } = Dropdown;

import { provideContext } from '../RenderContext';
import Recaptcha from '../Recaptcha';
import validate from './validate';
import util from '../../util';

class InnerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canInteract: false
    };

    this.products = props.settings.env !== 'production' ? [0] : [
      15,
      7,
      56,
      43,
      3,
      9,
      11,
      6,
      25,
      18,
      8,
      148,
      349,
      57,
      24,
      16,
      190,
      0
    ];

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      canInteract: true
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();

    this.captcha.execute();
  }

  render() {
    const {
      values,
      touched,
      dirty,
      errors,
      handleChange,
      handleBlur,
      isSubmitting,
      setFieldValue,
      setFieldTouched,
      submitForm,
      settings,
      intl
    } = this.props;

    const { canInteract } = this.state;

    const disabled = !canInteract || isSubmitting;
    const hasErrors = !!Object.keys(errors).length;
    const hasProductError = touched.productId && !!errors.productId;
    const hasDescriptionError = touched.description && !!errors.description;

    const handleProductChange = e => {
      setFieldValue('productId', e.value);
    };

    const handleProductToggle = open => {
      if (!open) {
        setFieldTouched('productId');
      }
    };

    return (
      <form onSubmit={ this.handleFormSubmit }>
        { util.shouldRenderEnglishOnlySupportDisclaimer(settings) && (
          <p className="contactUs-englishOnly"><FormattedMessage id="contactUs.englishOnly" /></p>
        ) }
        <fieldset
          className={ classNames('form-group', { 'has-danger': hasProductError }) }
          data-eid="storefront.contact_us.form.product.wrapper"
        >
          <Dropdown
            id="contactUs-form-product"
            type="select"
            label={ <span><FormattedMessage id="contactUs.form.labels.product" />&nbsp;</span> }
            onChange={ handleProductChange }
            onToggle={ handleProductToggle }
            selected={ values.productId != null ? [this.products.indexOf(values.productId)] : [] }
            disabled={ disabled }
            required={ true }
          >
            { this.products.map(key => (
              <DropdownItem key={ key } value={ key }>
                <FormattedMessage id={ `contactUs.form.products.${key}` } />
              </DropdownItem>
            )) }
          </Dropdown>

          { hasProductError && (
            <span className="form-error">
              <FormattedMessage id={ `contactUs.form.${errors.productId}` } />
            </span>
          ) }
        </fieldset>

        <div className="row">
          <div className="col-md-6">
            <Input
              id="contactUs-form-name"
              name="name"
              label={ intl.formatMessage({ id: 'contactUs.form.labels.name' }) }
              onBlur={ handleBlur }
              onChange={ handleChange }
              value={ values.name }
              disabled={ disabled }
              required={ true }
              data-eid="storefront.contact_us.form.name.input"
              validates={ touched.name && !errors.name }
              failure={ touched.name && errors.name && intl.formatMessage({ id: `contactUs.form.${errors.name}` }) }
            />
          </div>

          <div className="col-md-6">
            <Input
              id="contactUs-form-email"
              name="email"
              type="email"
              label={ intl.formatMessage({ id: 'contactUs.form.labels.emailAddress' }) }
              onBlur={ handleBlur }
              onChange={ handleChange }
              value={ values.email }
              disabled={ disabled }
              required={ true }
              data-eid="storefront.contact_us.form.email.input"
              validates={ touched.email && !errors.email }
              failure={ touched.email && errors.email && intl.formatMessage({ id: `contactUs.form.${errors.email}` }) }
            />
          </div>
        </div>

        <Input
          id="contactUs-form-subject"
          name="subject"
          label={ intl.formatMessage({ id: 'contactUs.form.labels.subject' }) }
          onBlur={ handleBlur }
          onChange={ handleChange }
          value={ values.subject }
          disabled={ disabled }
          required={ true }
          data-eid="storefront.contact_us.form.subject.input"
          validates={ touched.subject && !errors.subject }
          failure={ touched.subject &&
            errors.subject &&
            intl.formatMessage({ id: `contactUs.form.${errors.subject}` })
          }
        />

        <fieldset className={ classNames('form-group', { 'has-danger': hasDescriptionError }) }>
          <label htmlFor="contactUs-form-description" id="label-contactUs-form-description">
            <FormattedMessage id="contactUs.form.labels.description" />
            &nbsp;<span className="req" aria-label="required">*</span>
          </label>

          <textarea
            id="contactUs-form-description"
            name="description"
            aria-labelledby="label-contactUs-form-description"
            aria-required="true"
            className={ classNames('form-control', { 'form-control-danger': hasDescriptionError }) }
            onBlur={ handleBlur }
            onChange={ handleChange }
            value={ values.description }
            disabled={ disabled }
            data-eid="storefront.contact_us.form.description.input"
          />

          { hasDescriptionError && (
            <span className="form-error">
              <FormattedMessage id={ `contactUs.form.${errors.description}` } />
            </span>
          ) }
        </fieldset>

        <Recaptcha
          locale={ settings.locale }
          siteKey={ settings.recaptcha.siteKey }
          ref={ captcha => {
            this.captcha = captcha;
          } }
          onChange={ ({ recaptcha }) => {
            setFieldValue('recaptcha', recaptcha);

            submitForm();
          } }
        />

        <Button
          design="primary"
          type="submit"
          disabled={ disabled || hasErrors || !dirty }
          data-eid="storefront.contact_us.form.submit.click"
        >
          { isSubmitting && (
            <Spinner size="sm" inline shade="dark" />
          ) }
          <FormattedMessage id="contactUs.form.button" />
        </Button>
      </form>
    );
  }
}

InnerForm.propTypes = {
  ...util.formikPropTypes,
  intl: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired
};

class Form extends Component {
  render() {
    const {
      intl,
      settings,
      handleSubmit,
      shopper
    } = this.props;

    const initialValues = {};

    if (shopper) {
      initialValues.name = [shopper.firstName, shopper.lastName].join(' ').trim();
    }

    const formProps = {
      onSubmit: handleSubmit,
      initialValues,
      validate,
      render: formikProps => <InnerForm intl={ intl } settings={ settings } { ...formikProps } />
    };

    return (
      <div className="contactUs-form">
        <Formik { ...formProps } />
      </div>
    );
  }
}

Form.propTypes = {
  intl: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  shopper: PropTypes.object
};

export default provideContext(injectIntl(Form));
