import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Config from '@mcx/configure';

import { provideContext } from '../RenderContext';

class Configuration extends Component {
  constructor() {
    super(...arguments);

    this.state = {
      shouldRenderConfig: false
    };
  }

  componentDidMount() {
    this.setState({
      shouldRenderConfig: window && global.window
    });
  }

  render() {
    let content = '';

    if (this.state.shouldRenderConfig) {
      const { settings } = this.props;
      const { query } = settings.location;

      const initProps = {
        environment: settings.env,
        packageId: query.plan || query.packageId,
        plid: settings.privateLabelId,
        src: query.src,
        itc: query.itc,
        xs: query.xs,
        content: {
          loadingMessage: settings.messages['config.loadingMessage'],
          apiError: settings.messages['config.apiError']
        }
      };

      content = (<Config initProps={ initProps } />);
    }

    return (
      <div>
        { content }
      </div>
    );
  }
}

Configuration.propTypes = {
  settings: PropTypes.object.isRequired
};

export default provideContext(Configuration);
