import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  injectIntl,
  FormattedMessage
} from 'react-intl';

import {
  Button,
  Component,
  Modal
} from '@ux/uxcore2';

import IconChevronDownLt from '@ux/icon/chevron-down-lt';

import { enums } from 'rcc-helpers';

import Card from '../Card';
import util from '../../util';
import { provideContext } from '../RenderContext';

const taxAndFeeKeys = Object.keys(enums.taxesAndFees);

export class ProductCard extends Component {
  constructor() {
    super(...arguments);

    this.state = {
      canInteract: false,
      heightHasBeenCalculated: false
    };
  }

  componentDidMount() {
    this.breakpoint('mobile', 'phablet', 'tablet', 'desktop', 'large');

    this.setState({
      canInteract: true
    });
  }

  componentWillUnmount() {
    this.breakup();
  }

  render() {
    const { product, showMore, settings, cta, terse } = this.props;

    const {
      envPrefix,
      privateLabelId,
      itc
    } = settings;

    const {
      canInteract,
      shouldShowMoreModal
    } = this.state;

    const canCalculateHeight = showMore && !!this.details && !!this.details.scrollHeight;
    const baseHeight = terse ? 400 : 500;
    const shouldShowMoreButton = canCalculateHeight && this.details.scrollHeight > baseHeight;

    const details = (
      <div
        className="product-description"
        dangerouslySetInnerHTML={{ __html: product.content }}
      />
    );

    const price = (
      <p className="product-price">
        { product.salePrice && (
          <span>
            <strike className="product-amount text-muted">
              { product.listPrice }
            </strike>&nbsp;
          </span>
        ) }

        <strong className="product-amount text-primary">
          { product.salePrice || product.listPrice }
          { /* keep these together to preserve the breaking space */ }
        </strong> <FormattedMessage id={ `products.terms.${product.oneTimeCharge ? 'each' : product.term}` }>
          { text => <span className="product-term">{text}</span> }
        </FormattedMessage>
      </p>
    );

    const taxesAndFeesDisclaimer = product.displayTaxesAndFees &&
      taxAndFeeKeys.includes(product.displayTaxesAndFees) && (
      <p
        className="product-taxAndFeeDisclaimer"
        data-eid={ `storefront.product.taxAndFeeDisclaimer.${product.displayTaxesAndFees.toLowerCase()}` }
      >
        <FormattedMessage id={ `products.taxAndFeeDisclaimers.${product.displayTaxesAndFees}` } />
      </p>
    );

    const action = `https://www.${envPrefix}secureserver.net/api/v1/cart/${privateLabelId}?itc=${itc}&redirect=true`;

    const items = [{
      id: product.id,
      quantity: 1
    }];

    const button = (
      <form method="POST" action={ action }>
        <input type="hidden" name="items" value={ JSON.stringify(items) } />

        <Button
          className="product-addToCart"
          type="submit"
          disabled={ !canInteract }
          data-eid={ `storefront.product.${util.snakeCase(product.id)}.add_to_cart.button.click` }
        >
          <FormattedMessage id={ `products.${cta}` } />
        </Button>
      </form>
    );

    const productBody = (
      <div>
        <h4 className="product-title">{ product.title }</h4>
        { price }
        { taxesAndFeesDisclaimer }
        { button }
        { details }
      </div>
    );

    const moreModal = (
      <Modal onClose={ () => this.setState({ shouldShowMoreModal: false }) }>
        { productBody }
      </Modal>
    );

    const moreButton = (
      <p className="product-more-wrapper text-center">
        <Button design="link" size="small" onClick={ () => this.setState({ shouldShowMoreModal: true }) }>
          <span>
            <FormattedMessage
              id="products.details.more"
            >
              { text => <><span>{ text }</span>&nbsp;<IconChevronDownLt/></> }
            </FormattedMessage>
          </span>
        </Button>
      </p>
    );

    const detailsClassNames = classNames(
      'product-details',
      terse && 'terse',
      canCalculateHeight && 'product-details--calculated',
      shouldShowMoreButton && 'product-details--more'
    );

    return (
      <Card className="product-card">
        <div className={ detailsClassNames } ref={ d => {
          this.details = d;
        } }>
          { productBody }
        </div>

        { shouldShowMoreButton && moreButton }
        { shouldShowMoreModal && moreModal }
      </Card>
    );
  }
}

ProductCard.propTypes = {
  intl: PropTypes.object.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    salePrice: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string
    ]),
    listPrice: PropTypes.string.isRequired,
    term: PropTypes.string.isRequired,
    displayTaxesAndFees: PropTypes.string,
    oneTimeCharge: PropTypes.bool
  }).isRequired,
  showMore: PropTypes.bool,
  settings: PropTypes.object.isRequired,
  cta: PropTypes.string,
  terse: PropTypes.bool
};

ProductCard.defaultProps = {
  cta: 'addToCart'
};

export default provideContext(injectIntl(ProductCard));
