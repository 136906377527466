import React from 'react';
import { FormattedMessage } from 'react-intl';
import { utils } from '@ux/uxcore2';

import { provideContext } from '../RenderContext';
import { FilteredProducts } from './FilteredProducts';

export class Vps extends FilteredProducts {
  constructor() {
    super(...arguments);

    this.filters = [{
      key: 'standardRam',
      filter: p => p.tags.includes('standard-ram')
    }, {
      key: 'highRam',
      filter: p => p.tags.includes('high-ram')
    }];

    this.productCardProps = {
      cta: 'configureYourServer',
      terse: true
    };

    this.generatePodsHeaderContent = this.generatePodsHeaderContent.bind(this);
    this.generatePodsFooterContent = this.generatePodsFooterContent.bind(this);
  }

  generatePodsHeaderContent() {
    const { filterIndex } = this.state;

    return (
      <div className="col-xs-12">
        <ul className="vps-filter">
          {this.filters.map((filter, n) => {
            if (n === filterIndex) {
              return (
                <li key={ filter.key } className="text-primary">
                  <FormattedMessage
                    id={ `products.filter.${filter.key}` }
                  />
                </li>
              );
            }

            const handleClick = e => {
              e.preventDefault();

              this.setState({ filterIndex: n });
            };

            return (
              <li key={ filter.key } className="text-muted">
                <a /* eslint-disable-line jsx-a11y/anchor-is-valid */
                  href="#"
                  className="products-filter text-muted"
                  onClick={ handleClick }
                >
                  <FormattedMessage
                    id={ `products.filter.${filter.key}` }
                  />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  generatePodsFooterContent() {
    if (!/^en-/.test(this.props.settings.market)) {
      return null;
    }

    let messageKey = 'viewSelfManaged';
    const url = new utils.URL('/products/vps');

    if (this.props.tag.id === 'vps') {
      messageKey = 'viewFullyManaged';

      url.set('query', { tag: 'managed-vps' });
    }

    const { isOnCustomDomain, privateLabelId } = this.props.settings;

    if (!isOnCustomDomain) {
      url.set('query', {
        ...url.query,
        plid: privateLabelId
      });
    }

    return (
      <div className="col-xs-12">
        <p className="vps-switch">
          <FormattedMessage
            id={ `products.vps.${messageKey}` }
            values={{
              a: (msg) => (
                <a href={ url }>{msg}</a>
              )
            }}
          />
        </p>
      </div>
    );
  }
}

export default provideContext(Vps);
