import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { provideContext } from '../RenderContext';
import PageTitle from '../PageTitle';
import Card from '../Card';

const NotFound = ({ settings }) => {
  const { privateLabelId } = settings;

  return (
    <div className="notFound">
      <PageTitle>
        <h1 className="headline-brand"><FormattedMessage id="notFound.header" /></h1>
      </PageTitle>
      <div className="container row">
        <Card>
          <p>
            <FormattedMessage
              id="notFound.message"
              values={{
                a: (msg) => (
                  <a href={ `/?plid=${privateLabelId}` }>
                    {msg}
                  </a>
                )
              }}
            />
          </p>
        </Card>
      </div>
    </div>
  );
};

NotFound.propTypes = {
  settings: PropTypes.object.isRequired
};

export default provideContext(NotFound);
