import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Alert, Search } from '@ux/uxcore2';

import { provideContext } from '../RenderContext';

import util from '../../util';
import { redeemCode } from './util';

const Redemption = ({ intl, settings }) => {
  const [state, setState] = useState({
    isSubmitting: false,
    error: null
  });

  const handleRedemptionCodeSubmit = redemptionCode => {
    // Search component doesn’t support a disabled state, so bail if currently
    // submitting.
    if (state.isSubmitting) {
      return;
    }
    // Can also bail with `noCode` error if redemption code is empty.
    else if (!redemptionCode) {
      return setState({
        isSubmitting: false,
        error: 'noCode'
      });
    }

    setState({
      isSubmitting: true,
      error: null
    });

    const { privateLabelId, envPrefix } = settings;

    redeemCode(privateLabelId, redemptionCode)
      .then(() => {
        // Intentionally not resetting state here so that the user can’t
        // re-submit a different code while the cart redirect is loading.

        util.redirect(`https://cart.${envPrefix}secureserver.net/?plid=${privateLabelId}`);
      })
      .catch(error => {
        setState({
          isSubmitting: false,
          error: error.key || 'general'
        });
      });
  };

  return (
    <div className="bg-primary-o-highlight">
      <div className="container redemption">
        <h1 className="headline-brand text-center">
          <FormattedMessage id="redemption.title" />
        </h1>

        {state.error && (
          <Alert
            type="warning"
            message={ intl.formatMessage({ id: `redemption.form.error.${state.error}` }) }
            dismissible={ false }
          />
        )}

        <Search
          size="large"
          placeholder={ intl.formatMessage({ id: 'redemption.form.placeholder' }) }
          icon={ false }
          button={ true }
          buttonText={ intl.formatMessage({ id: 'redemption.form.submit' }) }
          onSearch={ handleRedemptionCodeSubmit }
        />
      </div>
    </div>
  );
};

Redemption.propTypes = {
  intl: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired
};

export default provideContext(injectIntl(Redemption));
