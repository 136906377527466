import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { provideContext } from '../RenderContext';
import UnfilteredProducts from './UnfilteredProducts';
import DedicatedServer from './DedicatedServer';
import Vps from './Vps';
import Config from '../Config';
import util from '../../util';

export class Products extends Component {
  render() {
    const { tag, settings } = this.props;
    const { query } = settings.location;

    if (util.shouldRenderConfig(query)) {
      return <Config />;
    }

    if (tag) {
      if (['vps', 'managed-vps'].includes(tag.id)) {
        return <Vps />;
      }
      else if (tag.id === 'dedicated-server') {
        return <DedicatedServer />;
      }
    }

    return <UnfilteredProducts />;
  }
}

Products.propTypes = {
  tag: PropTypes.object,
  settings: PropTypes.object.isRequired
};

export default provideContext(Products);
