import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PageTitle from './PageTitle';
import ProductIcon from './ProductIcon';
import { ProductBanner } from './ProductBanner';

// Title is still needed for screenreader purposes
export const  BannerLayout = ({ image, title, description, children }) => (
  <PageTitle>
    <div className={ classNames('product-header-container banner', !!description && 'has-description') }>
      <ProductBanner imageUrl={ image } title={ title }/>
      <h1 className="headline-brand screenreader">{ title }</h1>
      { description && (
        <div className="row">
          <div className="col-md-10 push-md-1">
            <p className="product-header-description text-center">{ description }</p>
          </div>
        </div>
      ) }
      { children }
    </div>
  </PageTitle>
);

export const IconLayout = ({ imageId, title, description, children }) => (
  <PageTitle>
    <div className={ classNames('product-header-container', !!description && 'has-description') }>
      <h1 className="headline-brand">
        <ProductIcon id={ imageId } />
        { title }
      </h1>
      { description && (
        <p className="product-header-description">{ description }</p>
      ) }
      { children }
    </div>
  </PageTitle>
);


const Layout = {
  BANNER: 'BANNER',
  ICON: 'ICON'
};

function whichLayout({
  imageId
}) {
  // This is hardcoded for now for m365 as it's the only product which has this
  // if we find we're getting this request for multiple products then we can add
  // a field to the product definition for its image layout
  if (imageId === 'm365') {
    return Layout.BANNER;
  }

  return Layout.ICON;
}

export const ProductTitle = (props) => {

  const layout = whichLayout(props);

  if (layout === Layout.BANNER) {
    return (
      <BannerLayout { ...props }/>
    );
  }

  return (
    <IconLayout { ...props }/>
  );
};

BannerLayout.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node
};

IconLayout.propTypes = {
  imageId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node
};

export default ProductTitle;
