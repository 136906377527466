import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'querystring';

import {
  injectIntl
} from 'react-intl';

import { utils, Growl, Modal } from '@ux/uxcore2';

import { provideContext } from '../../RenderContext';
import Form from './form';

const { addGrowlMessage } = Growl;

class ContactForm extends Component {
  constructor() {
    super(...arguments);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(values, { setSubmitting }) {
    const {
      intl,
      settings,
      handleClose
    } = this.props;

    const {
      envPrefix,
      privateLabelId
    } = settings;

    const url = `https://www.${envPrefix}secureserver.net/whois/contact.aspx?plid=${privateLabelId}`;

    const data = {
      'domainName': values.domain,
      'requesterEmailAddress': values.emailAddress,
      'contactReasonMessage': values.reason.trim(),
      'contactType': values.type,
      privateLabelId,
      'isSecureserver': true,
      'g-Recaptcha-Response': values.recaptcha,
      'UseReCaptcha': true
    };

    const options = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      // Disable credentials inclusion by providing a `mode` with a value other
      // than 'cors'.
      mode: ''
    };

    utils.request.post(url, queryString.stringify(data), options, error => {
      const isError = !!error;
      const key = isError ? 'error' : 'success';

      addGrowlMessage({
        title: intl.formatMessage({ id: `whois.contact.form.growls.${key}.title` }),
        content: intl.formatMessage({ id: `whois.contact.form.growls.${key}.content` }),
        icon: key,
        fadeTime: 5000
      });

      setSubmitting(false);

      if (!isError) {
        handleClose();
      }
    });
  }

  render() {
    const { initialValues, handleClose, intl } = this.props;

    return (
      <div className="whois-contact">
        <Modal title={ intl.formatMessage({ id: 'whois.contact.title' }) } onClose={ handleClose }>
          <Form initialValues={ initialValues } handleClose={ handleClose } handleSubmit={ this.handleFormSubmit } />
        </Modal>
      </div>
    );
  }
}

ContactForm.propTypes = {
  intl: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  handleClose: PropTypes.func.isRequired
};

export default provideContext(injectIntl(ContactForm));
