import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import { provideContext } from '../RenderContext';
import { FilteredProducts } from './FilteredProducts';

export class DedicatedServer extends FilteredProducts {
  constructor() {
    super(...arguments);

    this.filters = [{
      key: 'linux',
      filter: p => p.tags.includes('linux')
    }, {
      key: 'windows',
      filter: p => p.tags.includes('windows')
    }];

    this.generateAdditionalTitleContent = this.generateAdditionalTitleContent.bind(this);
  }

  generateAdditionalTitleContent({ canInteract }) {
    const { intl } = this.props;
    const { filterIndex } = this.state;
    const nextFilterIndex = (filterIndex + 1) % this.filters.length;

    const handleClick = e => {
      e.preventDefault();

      if (!canInteract) {
        return false;
      }

      this.setState({
        filterIndex: nextFilterIndex
      });
    };

    return (
      <p>
        <a /* eslint-disable-line jsx-a11y/anchor-is-valid */
          href="#"
          className={ classNames('products-filter', { 'text-muted': !canInteract }) }
          onClick={ handleClick }
        >
          { intl.formatMessage({
            id: 'products.filter.label'
          },
          {
            which: intl.formatMessage({
              id: `products.filter.${this.filters[nextFilterIndex].key}`
            })
          }) }
        </a>
      </p>
    );
  }
}

DedicatedServer.propTypes = {
  intl: PropTypes.object.isRequired
};

export default provideContext(injectIntl(DedicatedServer));
