import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from '@ux/uxcore2';

import { provideContext } from '../RenderContext';
import PageTitle from '../../components/PageTitle';
import Card from '../../components/Card';
import util from '../../util';

export class LegalAgreement extends Component {
  constructor(props) {
    super(props);

    this.handleCloseDisclaimer = this.handleCloseDisclaimer.bind(this);

    this.state = {
      showDisclaimer: !props.settings.market.startsWith('en-')
    };
  }

  componentDidMount() {
    if (!this.props.legalAgreement) {
      util.redirect(`/legal-agreements?plid=${this.props.settings.privateLabelId}`);
    }
  }

  handleCloseDisclaimer() {
    this.setState({
      showDisclaimer: false
    });
  }

  render() {
    const { legalAgreement } = this.props;

    return (
      <div className="legalAgreements">
        <PageTitle>
          <h1 className="headline-brand"><FormattedMessage id="legalAgreements.title" /></h1>
        </PageTitle>
        <div className="container row">
          <Card>
            {legalAgreement && (
              <div dangerouslySetInnerHTML={{ __html: legalAgreement.body }} />
            )}
          </Card>
        </div>

        { this.state.showDisclaimer && (
          <Modal onClose={ this.handleCloseDisclaimer }>
            <p><FormattedMessage id="legalAgreements.translationDisclaimer.body" /></p>
            <Button onClick={ this.handleCloseDisclaimer }>
              <FormattedMessage id="legalAgreements.translationDisclaimer.action" />
            </Button>
          </Modal>
        ) }
      </div>
    );
  }
}

LegalAgreement.propTypes = {
  settings: PropTypes.object.isRequired,
  legalAgreement: PropTypes.object
};

export default provideContext(LegalAgreement);
