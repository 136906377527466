import { validation } from 'rcc-helpers';

export default values => {
  const errors = {};

  if (!values.name || values.name.trim() === '') {
    errors.name = 'required';
  }

  if (!values.email || values.email.trim() === '') {
    errors.email = 'required';
  }
  else if (!validation.emailAddress(values.email)) {
    errors.email = 'invalid';
  }

  if (values.productId == null) {
    errors.productId = 'required';
  }

  if (!values.subject || values.subject.trim() === '') {
    errors.subject = 'required';
  }

  if (!values.description || values.description.trim() === '') {
    errors.description = 'required';
  }

  return errors;
};
