import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Alert, Tooltip } from '@ux/uxcore2';

import PromotionBanner from './PromotionBanner';

class HostingPromotionBanner extends PromotionBanner {
  constructor() {
    super();

    this.dismissCookieName = 'sf.suppressBanner.hostingPromotion';
    this.renderAlert = this.renderAlert.bind(this);
  }

  renderAlert() {
    return (
      <Alert
        className="hostingPromotionBanner"
        type="offer"
        onClose={ this.handleDismiss }
        message={ (
          <Fragment>
            <FormattedMessage id="home.hostingPromotionBanner.title" />
            { this.isMobile
              ? <Tooltip message={ this.props.intl.formatMessage({ id: 'home.hostingPromotionBanner.tooltip' }) } />
              : <Fragment>&nbsp;<FormattedMessage id="home.hostingPromotionBanner.tooltip" /></Fragment>
            }
          </Fragment>
        ) }
      />
    );
  }
}

HostingPromotionBanner.propTypes = {
  intl: PropTypes.object.isRequired
};

export default injectIntl(HostingPromotionBanner);
