import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { provideContext } from '../RenderContext';
import DomainPromotionBanner from './DomainPromotionBanner';
import HostingPromotionBanner from './HostingPromotionBanner';
import SecurityBundlePromotionBanner from './SecurityBundlePromotionBanner';

const isOptedInToPromotion = (promotions, key) => !!promotions && !!promotions.find(p => p.key === key);

// Tokens for how and when to render a given banner.
// - `component`: the component to render if selected
// - `promotionKey`: for checking opt-in status
// - `productIsNotHidden`: for confirming that products related to this
//   promotion have not been hidden by reseller.
// - `productIsApplicable`: for confirmating that promotion applies to a
//   specified product tag.
const banners = {
  domains: {
    component: DomainPromotionBanner,
    promotionKey: 'year-long-domain-promotion',
    productIsNotHidden: navigation => !!navigation &&
      !navigation.domains.isHidden &&
      !navigation.domains.children.domainRegistration.isHidden,
    productIsApplicable: tag => ['domain-registration'].includes(tag)
  },
  hosting: {
    component: HostingPromotionBanner,
    promotionKey: 'hosting-promotions-for-yearly-terms',
    productIsNotHidden: navigation => !!navigation &&
      !navigation.hosting.isHidden,
    productIsApplicable: tag => [
      'cpanel',
      'plesk',
      'wordpress',
      'business',
      'vps',
      'dedicated-server'
    ].includes(tag)
  },
  securityBundle: {
    component: SecurityBundlePromotionBanner,
    promotionKey: '30-percent-off-security-bundle',
    productIsNotHidden: navigation => !!navigation &&
      !navigation.hosting.isHidden &&
      !navigation.security.isHidden,
    productIsApplicable: tag => [
      'cpanel',
      'plesk',
      'wordpress',
      'business',
      'ssl',
      'website-backup',
      'website-security'
    ].includes(tag)
  }
};

class AtMostOnePromotionBanner extends Component {
  constructor() {
    super(...arguments);

    this.state = {};

    const {
      preference,
      skipNavigationCheck,
      productTag,
      navigation,
      promotions
    } = this.props;

    // Iterate the ordered list of token keys and select the first one where
    // all checks pass.
    for (let n = 0; n < preference.length; n++) {
      const banner = banners[preference[n]];

      if (!banner) {
        break;
      }

      const isOptedIn = isOptedInToPromotion(promotions, banner.promotionKey);
      const productIsNotHidden = !!skipNavigationCheck || banner.productIsNotHidden(navigation);
      const productIsApplicable = !productTag || banner.productIsApplicable(productTag);

      if (isOptedIn && productIsNotHidden && productIsApplicable) {
        this.state.PromotionBanner = banner.component;

        break;
      }
    }
  }

  render() {
    // The selection above may not have found an appropriate banner so render
    // is conditional.

    const { PromotionBanner } = this.state;

    return !!PromotionBanner && <PromotionBanner />;
  }
}

AtMostOnePromotionBanner.propTypes = {
  settings: PropTypes.object.isRequired,
  preference: PropTypes.array.isRequired,
  skipNavigationCheck: PropTypes.bool,
  productTag: PropTypes.string,
  navigation: PropTypes.object,
  promotions: PropTypes.array
};

AtMostOnePromotionBanner.defaultProps = {
  preference: []
};

export default provideContext(AtMostOnePromotionBanner);
