import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '@ux/uxcore2';

import { provideContext } from '../RenderContext';
import Card from '../Card';
import util from '../../util';

const SideBar = ({ settings, support }) => {
  const {
    envPrefix,
    privateLabelId
  } = settings;

  return (
    <div className="contactUs-sidebar col-lg-4">
      <div className="row">
        <div className="col-sm-6 col-lg-12">
          { support && !!support.technical && (
            <Card className="contactUs-sidebar-support">
              <h2><FormattedMessage id="contactUs.sidebar.support.title" /></h2>
              { util.shouldRenderEnglishOnlySupportDisclaimer(settings) && (
                <p className="contactUs-englishOnly"><FormattedMessage id="contactUs.englishOnly" /></p>
              ) }
              <p>
                <span className="contactUs-sidebar-support-label">
                  <FormattedMessage id="contactUs.sidebar.support.technical" />
                </span>
                <strong className="contactUs-sidebar-support-value">
                  <a href={ `tel:${util.stripNonNumerals(support.technical)}` }>{support.technical}</a>
                </strong>
              </p>
            </Card>
          ) }
        </div>

        <div className="col-sm-6 col-lg-12">
          <Card className="contactUs-sidebar-help">
            <h2><FormattedMessage id="contactUs.sidebar.help.title" /></h2>
            <p><FormattedMessage id="contactUs.sidebar.help.cta" /></p>
            <p>
              <Button href={ `https://www.${envPrefix}secureserver.net/help/?pl_id=${privateLabelId}` }>
                <FormattedMessage id="contactUs.sidebar.help.button" />
              </Button>
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
};

SideBar.propTypes = {
  settings: PropTypes.object.isRequired,
  support: PropTypes.object
};

export default provideContext(SideBar);
